import { Avatar, Menu } from '@mui/material'
import React from 'react'
import MenuIcon from '@mui/icons-material/Menu';
const Navbar = () => {
  return (

<div className="navbar px-3  bg-white border">
<MenuIcon className="text-primary menu-icon"/>
<div className="d-flex align-items-center">
    <p className='m-0 me-4'>Help</p>
    <p className='m-0 me-4'>Eng</p>
    <Avatar/>
</div>

</div>


  )
}

export default Navbar