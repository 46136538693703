import React from 'react'
import LabelDescription from '../components/LabelDescription';
import LabelContent from '../components/LabelContent';
import VerticalStepper from '../components/VerticalStepper';
import { QuestionAnswer } from '@mui/icons-material';
import Section2 from '../sections/Section2';
import Section3 from '../sections/Section3';
import Section4 from '../sections/Section4';
import { useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import CompanyExpectations from '../sections/CompanyExpectations';
import CustomerInformation from '../sections/CustomerInformation';
import { useParams } from 'react-router-dom';
const Page1 = () => {
    const sectionRef = useRef(null);
  const [image, setImage] = useState(null);
const params = useParams()
  const takeScreenshot = () => {
    if (sectionRef.current) {
      html2canvas(sectionRef.current).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        setImage(imgData); // Store the image temporarily
        localStorage.setItem("page_"+params.page_id,JSON.stringify(imgData))
      });
    }
  };

  return (
  <>
  
  <div className="col-5 col-12 p-4 bg-snow col-lg-5 col-md-12 my-3 my-lg-0">
<VerticalStepper/>

<div className='custom-contact mt-3 align-items-center d-flex border rounded rounded-2'>
<div className='rounded rounded-pill p-2 me-2 border border-info'>
<QuestionAnswer className='text-primary'/>

</div>
<div>
  <p className='m-0'>Having Troubles ?</p>
  <h5 className='m-0'>Contact Us</h5>
</div>

</div>

    </div>
    <div  className="col-7 col-12 p-4 col-lg-7 col-md-12 my-3 my-lg-0">


  <div ref={sectionRef} className='w-100' >
  <CustomerInformation takeScreenshot={takeScreenshot}/>

  </div>
    </div>
  
  </>
  )
}

export default Page1