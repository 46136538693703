import React from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'

import LabelDescription from '../components/LabelDescription';
import LabelContent from '../components/LabelContent';
import VerticalStepper from '../components/VerticalStepper';
import { QuestionAnswer } from '@mui/icons-material';
import Section2 from '../sections/Section2';
import Section3 from '../sections/Section3';
import Section4 from '../sections/Section4';
const DefaultTemplate = ({children}) => {
  return (
    <>






<div className="container-fluid">
    
<div className="d-flex">

<Sidebar/>


<div className="col-12 content">
<Navbar/>
<div className="p-0">
<div className="d-flex mb-5  mt-3 flex-wrap">

{children}
 
</div>

</div>

</div>

</div>

</div>

    </>
  )
}

export default DefaultTemplate