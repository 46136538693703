import React from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import films from './films';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
// or
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
const Section3 = ({takeScreenshot}) => {
  return (
   <>
   <div>
<h4>Select Distribution Channel</h4>
<p>About Company</p>
<hr></hr>


<div>

  <h6>What distribution channels to you participate in? (Check all that apply)</h6>

  <div className='d-flex mt-3'>


<button className='btn btn-outline-primary  rounded rounded-pill px-5 me-3'>Lite $ 400</button>

<button className='btn btn-primary rounded rounded-pill px-5' >Pro $ 600</button>
  </div>
</div>

 <div className='my-4'>
   
    <Autocomplete
  disablePortal
  options={films}
  className='w-100'
  sx={{ width: 300 }}
  renderInput={(params) => <TextField {...params} label="What’s your company’s industry / occupation" />}
/>

</div>


 <div className='my-4'>
   
    <Autocomplete
  disablePortal
  options={films}
  className='w-100'
  sx={{ width: 300 }}
  renderInput={(params) => <TextField {...params} label=" How many bookings do you currently produce daily?" />}
/>

</div>




<div className='d-flex'>

  <div className='col-lg-4 col-md-4 col-sm-6 pe-2 col-12'>
<div className='border border-1 border-primary rounded rounded-3 p-3'>
<p>Advanced Reports ?</p>

<FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="true" control={<Radio />} label="Yes" />
        <FormControlLabel value="false" control={<Radio />} label="No" />
       
      </RadioGroup>
    </FormControl>
</div>
  </div>




  <div className='col-lg-4 col-md-4 col-sm-6 pe-2 col-12'>
<div className='border border-1 border-primary rounded rounded-3 p-3'>
<p>Premium Support ?</p>

<FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="true" control={<Radio />} label="Yes" />
        <FormControlLabel value="false" control={<Radio />} label="No" />
       
      </RadioGroup>
    </FormControl>
</div>
  </div>

  <div className='col-lg-4 col-md-4 col-sm-6  col-12'>
<div className='border border-1 border-primary rounded rounded-3 p-3'>
<p>AI Chatbox ?</p>

<FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="true" control={<Radio />} label="Yes" />
        <FormControlLabel value="false" control={<Radio />} label="No" />
       
      </RadioGroup>
    </FormControl>
</div>
  </div>
</div>

   </div>
   

   <TextareaAutosize placeholder='Please provide an overview of your company or anything else you think
might be useful.' className='form-control p-3 text-area mt-3'/>

   <Link to="/4" onClick={()=>takeScreenshot()} className='btn mt-4 bg-theme rounded rounded-pill text-light w-100'>Next</Link>
   </>
  )
}

export default Section3