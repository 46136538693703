import React from 'react'
import { useParams } from 'react-router-dom'
import Page1 from './Page1'
import Page4 from './Page4'
import Page5 from './Page5'
import Page2 from './Page2'
import Page3 from './Page3'

const MainPage = () => {

    const params = useParams()
    const page_id = parseInt(params.page_id)
    console.log(page_id)
  return (
   <>
   {page_id===1?
   <>
   
   <Page1/>
   </>:null}
   {page_id===2?
   <>
   
   <Page2/>
   </>:null}



   {page_id===3?
   <>
   
   <Page3/>
   </>:null}


   {page_id===4?
   <>
   
   <Page4/>
   </>:null}

   {page_id===5?
   <>
   
   <Page5/>
   </>:null}
   
   </>
  )
}

export default MainPage