import React from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import films from './films';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import image from '../images/thank-you.png'
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
// or
import TextField from '@mui/material/TextField';
import { Check } from '@mui/icons-material';
const Section4 = () => {
  return (
   <>
   <div>
<div className='mx-auto d-none w-fit text-center'>
  <img src={image} className='col-3 mx-auto'/>
<h4>Thank you for registering here !</h4>

<p>Here are our tailored pricing options designed to
meet your needs and help you get the most out of our services.</p>


</div>
<hr className='d-none'></hr>


<div>

  <h6>White Lable Pro selected information</h6>
<p>List of lables</p>
<hr></hr>
  <div className='d-flex flex-wrap im-0'>
<div className='d-flex col-6 align-items-center'><Check className="me-2"/><p>Logo</p></div>
<div className='d-flex col-6 align-items-center'><Check className="me-2"/><p> Social Links AI chat, </p></div>
<div className='d-flex col-6 align-items-center'><Check className="me-2"/><p>Banner Text</p></div>
<div className='d-flex col-6 align-items-center'><Check className="me-2"/><p>Email support</p></div>
<div className='d-flex col-6 align-items-center'><Check className="me-2"/><p>Footer Copy Right Text
</p></div>
<div className='d-flex col-6 align-items-center'><Check className="me-2"/><p>Configurable Place </p></div>
  </div>
</div>






   </div>
   
   <button className='BTN btn-outline-primary btn rounded rounded-pill px-4 mt-4'>$ 600 </button>

<hr></hr>
<div className='d-flex justify-content-between '>
  
<div className='d-flex'>
<button className='btn me-3 bg-theme rounded rounded-pill text-light '>Back to Selection</button>

</div>


</div>
   </>
  )
}

export default Section4