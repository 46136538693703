import React, { useState } from 'react'
import FileIcon from '@mui/icons-material/InsertDriveFile';
import BuildIcon from '@mui/icons-material/Build';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import logo from '../images/logo.png'
import DnsIcon from '@mui/icons-material/Dns';
import BlockIcon from '../icons/blockicon.png'
import Offcanvas from 'react-bootstrap/Offcanvas';
const Sidebar = () => {


    const [visibility,setVisibility] = useState(false)
  return (
    <div onMouseOver={()=>{setVisibility(true)}} onMouseLeave={()=>{setVisibility(false)}} className={visibility?"sidebar sidebar-active":"sidebar sidebar-inactive"}>

<img className='logo' src={logo}></img>


<div className="icons">
<div className="d-flex sidebar-item">
<img src={BlockIcon} />
<h4>Server</h4>
</div>
<div className="d-flex sidebar-item">
<ShoppingCartIcon/>

<h4>Cart</h4>
</div>
<div className="d-flex sidebar-item">
<DnsIcon/>
<h4>Server</h4>
</div>
<div className="d-flex sidebar-item">
<FileIcon/>
<h4>Files</h4>
</div>
<div className="d-flex sidebar-item">
<BuildIcon/>
<h4>Builds</h4>
</div>

</div>



    </div>
  )
}

export default Sidebar