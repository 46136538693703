import React from 'react'
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
const CustomerInformation = ({takeScreenshot}) => {
  return (
    <div>

<h4>Customer Information</h4>
<h6>About</h6>
<hr></hr>


<div className="d-flex form-group flex-wrap">
<div className="col-6 my-3 my-md-3 px-0 px-md-2">
  <TextField
    required
    id="outlined-required-firstname"
    label="First Name"
    defaultValue=""
    className="w-100"
  />
</div>

<div className="col-6 my-3 my-md-3 px-0 px-md-2">
  <TextField
    required
    id="outlined-required-lastname"
    label="Last Name"
    defaultValue=""
    className="w-100"
  />
</div>

<div className="col-6 my-3 my-md-3 px-0 px-md-2">
  <TextField
    required
    id="outlined-required-mobile"
    label="Mobile Number"
    defaultValue=""
    className="w-100"
  />
</div>

<div className="col-6 my-3 my-md-3 px-0 px-md-2">
  <TextField
    required
    id="outlined-required-email"
    label="Email"
    defaultValue=""
    className="w-100"
  />
</div>

<div className="col-6 my-3 my-md-3 px-0 px-md-2">
  <TextField
    required
    id="outlined-required-companyname"
    label="Company Name"
    defaultValue=""
    className="w-100"
  />
</div>

<div className="col-6 my-3 my-md-3 px-0 px-md-2">
  <TextField
    required
    id="outlined-required-location"
    label="Location"
    defaultValue=""
    className="w-100"
  />
</div>

<div className="col-6 my-3 my-md-3 px-0 px-md-2">
  <TextField
    required
    id="outlined-required-websiteurl"
    label="Website URL"
    defaultValue=""
    className="w-100"
  />
</div>

<div className="col-6 my-3 my-md-3 px-0 px-md-2">
  <TextField
    required
    id="outlined-required-websitename"
    label="Website Name"
    defaultValue=""
    className="w-100"
  />
</div>


<div className="col-12 mt-3">
    <Link onClick={()=>takeScreenshot()} to="/2" className='w-100 btn rounded rounded-pill  text-light bg-theme'>Next Information</Link>
</div>
</div>


    </div>
  )
}

export default CustomerInformation