import { useState } from "react";
import { Link } from "react-router-dom"

export default function PaymentSuccessful() {
const [time,settime] = useState(5)
    setTimeout(()=>{
window.location.replace("/5")
    },5000)


    setTimeout(() => {
        settime(time-1)
    }, 1000);
  return (
 <>
 
 <main className="flex  items-center col-4 mx-auto border rounded rounded-3 justify-center flex-grow text-center p-4 ">
        <CircleCheckIcon className="h-16 w-16 text-green-500 text-success text-success success-icon" />
        <h1 className="mt-4 text-2xl font-semibold">Payment Successful</h1>
        <p className="mt-2 text-gray-500 dark:text-gray-400">Thank you for your purchase!</p>
        <div className="mt-6 border rounded-lg text-lg-start p-4 w-full max-w-md">
          <div className="flex justify-between text-sm">
            <span>Amount Paid:</span>
            <span className="font-medium">$100.00</span>
          </div>
          <div className="flex justify-between text-sm mt-2">
            <span>Date & Time:</span>
            <span className="font-medium">January 22, 2024, 10:30 AM</span>
          </div>
          <div className="flex justify-between text-sm mt-2">
            <span>Reference Number:</span>
            <span className="font-medium">1234567890</span>
          </div>
        </div>

        <p>Redirecting to preview page in {time} seconds</p>
        <Link
          href="#"
          className="mt-6 inline-flex items-center justify-center h-10 px-4 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          prefetch={false}
        >
          Return to Homepage
        </Link>

        <div className="d-flex justify-content-between">
            <button className="btn btn-theme rounded rounded-pill bg-theme text-light">View Menu</button>
            <button className="btn btn-primary rounded rounded-pill">Configure White Label</button>
        </div>
      </main>
      
      </>
  )
}

function CircleCheckIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <path d="m9 12 2 2 4-4" />
    </svg>
  )
}


function MountainIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m8 3 4 8 5-5 5 15H2L8 3z" />
    </svg>
  )
}