import React, { useEffect, useState } from 'react'
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import { Search } from '@mui/icons-material';
import SocialMediaSelector from '../sections/SocialMediaSelector';
const Page5 = () => {

    const [page1,setpage1] = useState(null)

    useEffect(()=>{
setpage1(JSON.parse(localStorage.getItem("page_1")))
    },[])
const [title,settitle] = useState("Enter website title")
const [bannertext,setbannertext] = useState("Enter banner text")
    const [websitename,setwebsitename] = useState("websitename")
  return (
    <div className='w-100'>


<div className='d-flex justify-content-evenly'>


<div className='col-7'>
<h4>Website Preview</h4>
    <hr></hr>
<div className='preview-screen rounded rounded-top rounded-3 rounded-bottom-0 p-3'>
<div className='d-flex im-0 align-items-center'>
<img src="" className='preview-logo rounded rounded-2 me-2' ></img>
<div>
    
<h4>{title}</h4>
<p>{bannertext}</p>
</div>
</div>

<hr></hr>
<div className='search-bar d-flex align-items-center bg-white rounded rounded-pill'>

<input value={websitename+".com"} className='form-control border-0'></input>
    <Search/>

</div>





<div className='d-flex flex-wrap'>

<div className='col-6'>
<div className='p-2 my-2'><img src="https://blog.air.irctc.co.in/wp-content/uploads/2020/05/Flight-ticket-enquiry.jpg" className='w-100'/>
</div>
    </div>






    <div className='col-6'>
<div className='p-2 my-2'><img src="https://s.tmimgcdn.com/scr/1200x627/275800/flightworld-airline-booking-search-engine-website-template_275814-4-original.png" className='w-100'/>
</div>
    </div>


    <div className='col-6'>
<div className='p-2 my-2'><img src="https://rapidapi.com/blog/wp-content/uploads/2019/12/booking.com_-1024x653.png" className='w-100'/>
</div>
    </div>

    <div className='col-6'>
<div className='p-2 my-2'><img src="https://s1.r29static.com/bin/entry/1ca/x/1803765/image.png" className='w-100'/>
</div>
    </div>

</div>









</div>
</div>

<div className='col-4'>
<div className='custom-upload rounded rounded-3 d-flex align-items-center justify-content-center'>
<p>Upload Logo</p>
</div>
<TextField
    required
    id="outlined-required-county"
    label="Website Name"
    defaultValue=""
    onChange={(e)=>setwebsitename(e.target.value)}
    className="w-100 my-3"
  />
<TextField
    required
    id="outlined-required-county"
    label="Website Title"
    defaultValue=""
    className="w-100 my-3"
    onChange={(e)=>settitle(e.target.value)}
  />
  <TextField
    required
    id="outlined-required-county"
    label="Banner text"
    defaultValue=""
    onChange={(e)=>setbannertext(e.target.value)}
    className="w-100 my-3"
  />

<div className='d-flex flex-wrap'>

    <div className='col-6 px-1'>
    <TextField
    required
    id="outlined-required-county"
    label="Email"
    
    defaultValue=""
    className="w-100 my-3"
  />
    </div>
    <div className='col-6 px-1' >
    <TextField
    required
    id="outlined-required-county"
    label="Phone"
    defaultValue=""
    className="w-100 my-3"
  />
    </div>

    <SocialMediaSelector/>


    <TextField
    required
    id="outlined-required-county"
    label="Copy right text"
    defaultValue=""
    className="w-100 my-3"
  />



<div className='d-flex w-100 justify-content-between'>

    <button className='btn btn-primary rounded rounded-pill'>Preview</button>


<div>

<button className='btn btn-outline-primary rounded rounded-pill px-4'>Refresh</button>
<button className='btn bg-theme text-light rounded rounded-pill px-4 ms-2'>Go Live</button>

</div>
</div>
</div>
</div>
</div>


    </div>
  )
}

export default Page5