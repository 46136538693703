import React from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import films from './films';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
const Section2 = ({takeScreenshot}) => {
  return (
   <>
   <div>
<h4>Company Expectations</h4>
<p>About Company</p>
<hr></hr>


 <div className='my-4'>
   
    <Autocomplete
  disablePortal
  options={films}
  className='w-100'
  sx={{ width: 300 }}
  renderInput={(params) => <TextField {...params} label="What’s your company’s industry / occupation" />}
/>

</div>


 <div className='my-4'>
   
    <Autocomplete
  disablePortal
  options={films}
  className='w-100'
  sx={{ width: 300 }}
  renderInput={(params) => <TextField {...params} label=" How many bookings do you currently produce daily?" />}
/>

</div>


 <div className='my-4'>
   
    <Autocomplete
  disablePortal
  options={films}
  className='w-100'
  sx={{ width: 300 }}
  renderInput={(params) => <TextField {...params} label="Expected annual booking volume with our inventory (USD" />}
/>

</div>


 <div className='my-4'>
   
    <Autocomplete
  disablePortal
  options={films}
  className='w-100'
  sx={{ width: 300 }}
  renderInput={(params) => <TextField {...params} label="What’s your main goal to lunching your App" />}
/>

</div>

   </div>
   
   

   <Link to="/3" onClick={()=>takeScreenshot()} className='btn bg-theme rounded rounded-pill text-light w-100'>Next</Link>
   </>
  )
}

export default Section2