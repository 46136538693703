
import DefaultTemplate from './template/DefaultTemplate';
import HomePage from './pages/HomePage'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainPage from './pages/MainPage';
import PaymentSuccessful from './pages/PaymentSuccessful';
import Page1 from './pages/Page1';
function App() {
  return (
<>

<div className='container-flid'>
<DefaultTemplate>

<BrowserRouter>


<Routes>
  <Route path='/' element={<Page1/>}></Route>
  
  <Route path='/:page_id' element={<MainPage/>}></Route>
  <Route path='/success' element={<PaymentSuccessful/>}></Route>
  </Routes>
  
  </BrowserRouter>

</DefaultTemplate>



</div>

</>
  );
}

export default App;
